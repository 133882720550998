import httpRequest from './request'

/**
 * @description 添加推广人员
 * @link https://api.thinkerx.com/web/#/174/12169
 * @params params
 */
export function addPromote(
  params: {
    name: string
    phone: string
    is_employe: string
  },
  cb?: () => void,
  errCb?: () => void
) {
  return httpRequest.post('seeyard/admin/promoter', params, cb, errCb)
}

/**
 * @description 修改推广码
 * @link https://api.thinkerx.com/web/#/174/12173
 * @params params
 */
export function editPromoteCode(
  params: {
    code: string
  },
  cb?: () => void,
  errCb?: () => void
) {
  return httpRequest.put('seeyard/admin/promote/code', params, cb, errCb)
}

/**
 * @description 修改推广人员
 * @link https://api.thinkerx.com/web/#/174/12170
 * @params params
 */
export function editPromote(
  params: {
    name: string
    phone: string
    is_employe: string //1在职2离职
    id: number
  },
  cb?: () => void,
  errCb?: () => void
) {
  return httpRequest.put('seeyard/admin/promoter', params, cb, errCb)
}
