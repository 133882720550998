export const tableColumns = [
  {
    title: '人员ID',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: '推广人员姓名',
    dataIndex: 'name',
  },
  {
    title: '联系电话',
    dataIndex: 'phone',
  },
  {
    title: '推广客户数',
    scopedSlots: { customRender: 'users' },
    key: 'users',
    sorter: true,
  },
  {
    title: '推广码',
    scopedSlots: { customRender: 'qrcode' },
    width: '126px',
  },
  {
    title: '在职状态',
    scopedSlots: { customRender: 'is_employe' },
  },

  {
    title: '添加时间',
    dataIndex: 'create_time',
    key: 'create_time',
    customRender: null,
    sorter: true,
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'operation' },
    width: '156px',
  },
]

// 推广详情columns
export const tableDetailColumns = [
  {
    title: '客户名称',
    dataIndex: 'name',
  },
  {
    title: '柜柜账号',
    dataIndex: 'phone',
  },
  {
    title: '注册时间',
    scopedSlots: { customRender: 'create_time' },
    sorter: true,
  },
]
